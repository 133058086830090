import React from "react";
import Layout from "../components/layout";
import LinksBox from "../components/LinksBox";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";
import { content_wrapper } from "../styles/modules/content.module.css";

const NotFoundPage = () => (
  <Layout locale="es">
    <article className={content_wrapper}>
      <h3>
        Lo sentimos esta página no pertenece a este sitio web continuá navegando
        en los siguientes enlaces:
      </h3>
      <LinksBox locale="es" slug="/404/" />
    </article>
  </Layout>
);

export const Head = () => {
  return (
    <BasicHeadTags
      title="Página no encontrada"
      noindex={true}
      nofollow={true}
    />
  );
};

export default NotFoundPage;
